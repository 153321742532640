import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import img from '../../assets/compliance-graphic.png';
import Button from 'react-bootstrap/Button';
import '../Home/Home.css'
import '../Contact/contact.css'

const Intro = () => {
  const [posttitle, setName] = useState('');
  const [description, setDesc] = useState('');
  const [excerpt, setExcerpt] = useState('');
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState('');
  const [error, SetError] = useState('');
  const [tags, setTags] = useState([]);
  const [date, setDate] = useState('');
  const params = useParams();
  let { slug } = useParams();

  const styles = {
    width: '950px',
  };

  const getTeamDetails = async () => {
    console.warn(params);
    let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/update-blog/${params.slug}`);
    result = await result.json();
    setName(result.posttitle);
    setDesc(result.description);
    setExcerpt(result.excerpt);
    setFileName(result.document)
    
    setTags(result.tags)
    setDate(new Date(parseInt(params.id.substring(0, 8), 16) * 1000).toLocaleString()); // Retrieve date from _id field
  };

  useEffect(() => {
    getTeamDetails();
  }, []);

  const [data, setData] = useState([]);

  const fetchData = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-posts`);
    const result = await response.json();
    const post = result.find(post => post.slug === slug); // find the current post based on the slug

      setTags(post.tags); // set the tags for the current post
      setDate(new Date(parseInt(post._id.substring(0, 8), 16) * 1000).toLocaleString()); // set the date for the current post
      setData([{ ...post }]); // set the data for the current post with the selectedTeam details
  };
      useEffect(() => {
        fetchData();
      }, []);

  return (
        <>
          <section className="contactintrosec">
      <Container>
        <Row className='py-5'>
          <Col>

              <h1 className=' contact-intro-title'>{posttitle}</h1>
              {data.map((row, index) => (
                <>
              {row.selectedTeam && (
                                        <>
                                 <p className='blog-date text-center'>{row.selectedTeam.membername}-{new Date(row.date).toLocaleDateString()}</p>

                                        </>
                                    )}
                                    {!row.selectedTeam && (
                                        <>
                                        <h6 className='blogpage-author'>Author Name</h6>
                                        <p className='blogpage-author-position'>Author Position</p>
                                        </>
                                    )}
                                    </>
                                    ))}

          </Col>
          
        </Row>
      </Container>
    </section>
        
        </>
    )
}

export default Intro