import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@material-ui/core";
import { Snackbar, Alert } from "@mui/material";

import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import ModeEditOutlineTwoToneIcon from "@mui/icons-material/ModeEditOutlineTwoTone";

import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import "../../index.css";
import { request } from "../../../helpers/fetch-wrapper";

const Data = () => {
  const [data, setData] = useState([]);
  const [sortOrder, setSortOrder] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [deleteAlert, setDeleteAlert] = useState({
    open: false,
    severity: "error",
    message: "",
  });

  const fetchData = async () => {
    const response = await request({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND}/api/get-collaborators-with-order`,
    });
    setData(response);
    const orders = [];
    response.map((collaborator, index) => {
      orders.push({
        _id: collaborator._id,
        order: collaborator.order ? collaborator.order : index,
      });
    });
    setSortOrder(orders);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const DeleteCollaborator = async (id) => {
    try {
      await request({
        url: `${process.env.REACT_APP_BACKEND}/api/delete-collaborator/${id}`,
        method: "delete",
      });

      fetchData();
    } catch (error) {
      setDeleteAlert({
        open: true,
        severity: "error",
        message: "Superadmin account can't be removed.",
      });
      console.error("Error deleting collaborator:", error);
    }
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Collaborators Entries</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <Link to="/add-collaborator">
                  <Button variant="primary" size="lg">
                    Add New
                  </Button>
                </Link>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-3">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>S.no #</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(data) && data.length > 0 ? (
                sortOrder.map((order, rowIndex) => (
                  <CreateRow
                    data={data}
                    rowIndex={rowIndex}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    DeleteCollaborator={DeleteCollaborator}
                    order={order}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>No records found.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
      <Snackbar
        open={deleteAlert.open}
        autoHideDuration={6000}
        onClose={() => setDeleteAlert({ ...deleteAlert, open: false })}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setDeleteAlert({ ...deleteAlert, open: false })}
          severity={deleteAlert.severity}
          sx={{ width: "100%" }}
        >
          {deleteAlert.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

const CreateRow = (props) => {
  const { data, order, rowIndex, page, rowsPerPage, DeleteCollaborator } =
    props;
  const collaborator = data.find((x) => x._id === order._id);

  return (
    <TableRow key={collaborator._id}>
      <TableCell className="collaboratorserial-cell">
        {rowIndex + 1 + page * rowsPerPage}
      </TableCell>
      <TableCell className="name-cell">{collaborator.fname}</TableCell>
      <TableCell className="email-cell">{collaborator.email}</TableCell>

      <TableCell className="action-cell">
        <div className="action-icons">
          <Link to={"/update-collaborator/" + collaborator._id}>
            <ModeEditOutlineTwoToneIcon />
          </Link>
          <DeleteTwoToneIcon
            onClick={() => DeleteCollaborator(collaborator._id)}
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default Data;
